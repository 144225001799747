import React, { useEffect, useState } from "react";
import { TailSpin } from 'react-loading-icons';

const Loader = () => {
    const [height, setHeight] = useState("100vh");

    useEffect(() => {
        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        
        setHeight(`${documentHeight}px`);
        
        // Prevent body scrolling when loader is active
        document.body.style.overflow = 'hidden';
        
        return () => {
            // Re-enable scrolling when component unmounts
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="fixed inset-0 z-50">
            <div 
                className="absolute inset-0 bg-black bg-opacity-40"
                style={{ height }}
            >
                <div className="fixed top-[70%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <TailSpin stroke="white" strokeWidth={3} className="w-20 h-20"/>
                </div>
            </div>
        </div>
    );
};

export default Loader;