import React from 'react';
import { motion } from 'framer-motion';

const ComingSoon = () => {
    return (<motion.div className="flex justify-center items-center h-screen w-full bg-gray-100 text-black text-center"
        initial={{ opacity: 0, y: 20, scale: 0.98 }}
        animate={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1], // Custom cubic-bezier easing
            }
        }}
        exit={{
            opacity: 0,
            y: -20,
            scale: 0.98,
            transition: {
                duration: 0.3,
                ease: 'easeIn'
            }
        }}
    >
        <h1 className="font-sans text-4xl md:text-7xl lg:text-8xl">
            Coming Soon<span className="dot animate-blink">.</span>
            <span className="dot animate-blink delay-200">.</span>
            <span className="dot animate-blink delay-400">.</span>
        </h1>
    </motion.div>
    );
};

export default ComingSoon;
