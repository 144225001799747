import React, { useEffect } from 'react';
//import BG from '../../assets/img/404.avif';
import { motion } from 'framer-motion';

const ErrorPage = () => {
  useEffect(() => {
    const animate = () => {
      let lFollowX = 0,
        lFollowY = 0,
        x = 0,
        y = 0,
        friction = 1 / 30;

      const moveBackground = () => {
        x += (lFollowX - x) * friction;
        y += (lFollowY - y) * friction;

        const translate = `translate(${x}px, ${y}px) scale(1.1)`;

        document.querySelector('.error-page-bg').style.transform = translate;

        window.requestAnimationFrame(moveBackground);
      };

      window.addEventListener('mousemove', (e) => {
        const lMouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - e.clientX));
        const lMouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - e.clientY));
        lFollowX = (20 * lMouseX) / 100;
        lFollowY = (10 * lMouseY) / 100;
      });

      moveBackground();
    };

    animate();
  }, []);

  return (
    <motion.div className="relative w-full overflow-hidden shadow-lg" initial={{ opacity: 0, y: 20, scale: 0.98 }}
      animate={{
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
          duration: 0.4,
          ease: [0.25, 0.1, 0.25, 1], // Custom cubic-bezier easing
        }
      }}
      exit={{
        opacity: 0,
        y: -20,
        scale: 0.98,
        transition: {
          duration: 0.3,
          ease: 'easeIn'
        }
      }}>
      <div className="grid place-items-center min-h-screen p-8">
        <div className="text-center relative z-10">
          <div className="max-w-3xl mx-auto text-center p-4">
            <h1 className="font-bold text-[#eee] opacity-40 text-[175px] lg:text-[180px] text-[100px]">404</h1>
            <h2 className="text-gray-200 text-3xl mb-2 font-medium">Page not found</h2>
            <p className="text-gray-300 text-base leading-6 mt-2">I tried to catch some trucks, but I missed it</p>
            <a href="/" className="inline-block mt-12 text-base border-2 border-white px-8 py-3 text-white">
              Back to Home
            </a>
          </div>
          <div className="text-center mt-8">
            <p className="text-gray-500">
              © 2024 Go Trans Logistics. All rights reserved.
            </p>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div> {/* Overlay */}
        <img src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729525285/404_mspkea.avif"} className="absolute top-0 left-0 w-full h-full object-cover transform scale-110 error-page-bg" alt="error" />
      </div>
    </motion.div>
  );
};

export default ErrorPage;
