import React from 'react';
import { motion } from 'framer-motion';

const NotFound = ({ title }) => {
    return (
        <motion.div className="flex justify-center items-center h-screen w-full bg-gray-100 text-black text-center"
            initial={{ opacity: 0, y: 20, scale: 0.98 }}
            animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: {
                    duration: 0.4,
                    ease: [0.25, 0.1, 0.25, 1], // Custom cubic-bezier easing
                }
            }}
            exit={{
                opacity: 0,
                y: -20,
                scale: 0.98,
                transition: {
                    duration: 0.3,
                    ease: 'easeIn'
                }
            }}
        >
            <p className="font-sans text-2xl lg:text-3xl">
                <span className='text-red-500'>Market Closed Now</span><br />{title}
            </p>

        </motion.div>
    );
};

export default NotFound;
