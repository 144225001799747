import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import logo from "../assets/img/logo-black.png";
import { MdHome } from "react-icons/md";
import { FaTruckLoading } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import { MdSupportAgent } from "react-icons/md";
import { IoIosPeople } from "react-icons/io"
import { FaRegNewspaper } from "react-icons/fa";
import { MdAddToPhotos } from "react-icons/md";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Lock scroll when the menu is open
  //  useEffect(() => {
  //   if (showMenu) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }

  //   // Cleanup on component unmount
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [showMenu]);

  return (
    <div className="flex justify-center overflow-hidden">
      <nav className="fixed flex justify-between items-center px-6 py-4 bg-[#FFED00] w-[90%] h-[7%] mx-auto mt-2.5 z-50">
        {/* Logo */}
        <NavLink to="/" className="flex-shrink-0">
          <img src={logo} alt="logo" className="lg:h-10 h-8" />
        </NavLink>

        {/* Mobile Menu */}
        <div className={`fixed lg:hidden top-0 right-0 bg-black bg-opacity-30 backdrop-blur-md w-[80%] h-full p-6 z-50 transition-transform transform ${showMenu ? 'translate-x-0' : 'translate-x-full'}`}>
          <ul className="flex flex-col space-y-10">
            <li>
              <NavLink to="/" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <MdHome /> Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/liveloads" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <FaTruckLoading /> Live Loads
              </NavLink>
            </li>
            <li>
              <NavLink to="/livetrucks" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <FaTruckFast /> Live Trucks
              </NavLink>
            </li>
            <li>
              <NavLink to="/transporters" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                 <MdAddToPhotos/> Transporters
              </NavLink>
            </li>
            <li>
              <NavLink to="/truckers" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <MdAddToPhotos/> Truckers
              </NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <IoIosPeople /> About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/news" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <FaRegNewspaper /> News
              </NavLink>
            </li>
            <li>
              <NavLink to="/contactus" className="flex items-center text-black text-xl gap-x-2" onClick={toggleMenu}>
                <MdSupportAgent /> Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transporters"
                className="bg-black text-white py-2 px-6 border-2 border-yellow-400 text-xl font-semibold hover:bg-white hover:text-black"
                onClick={toggleMenu}
              >
                Book a Truck
              </NavLink>
            </li>
          </ul>
          <div className="absolute top-6 right-6 text-black text-3xl cursor-pointer" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>


        {/* Menu Toggle for Mobile */}
        <div className="text-3xl text-black cursor-pointer lg:hidden" onClick={toggleMenu}>
          <IoMenu />
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-8 items-center">
          <li>
            <NavLink to="/" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
             <MdHome /> Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/liveloads" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <FaTruckLoading /> Live Loads
            </NavLink>
          </li>
          <li>
            <NavLink to="/livetrucks" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <FaTruckFast /> Live Trucks
            </NavLink>
          </li>
          <li>
            <NavLink to="/transporters" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <MdAddToPhotos/> Transporters
            </NavLink>
          </li>
          <li>
            <NavLink to="/truckers" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <MdAddToPhotos/> Truckers
            </NavLink>
          </li>
          <li>
            <NavLink to="/aboutus" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <IoIosPeople /> About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/news" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <FaRegNewspaper /> News
            </NavLink>
          </li>
          <li>
            <NavLink to="/contactus" className="flex items-center text-black font-semibold transition hover:text-yellow-500 gap-x-2">
              <MdSupportAgent /> Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/transporters"
              className="bg-black text-white py-2 px-6 border-2 border-yellow-400 font-semibold transition hover:bg-white hover:text-black hover:border-black"
            >
              Book a Truck
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
